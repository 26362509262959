import React from "react";
import { IgniteYourContent } from "./Content";
import { useApplication } from "@ryerson/frontend.application";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Accordion, AccordionTab } from "@ryerson/frontend.expansion";
import { ButtonLink, Link } from "@ryerson/frontend.navigation";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

export type IgniteYourCareerCard = {
	title: string;
	description: string;
	actionLabel?: string;
	actionUrl?: string;
	mobileImage: string;
	desktopImage: string;
};

export type IgniteYourCareerContent = {
	title1: string;
	title2: string;
	navLabel?: string;
	description?: string;
	cards: IgniteYourCareerCard[];
};

export type IgniteYourCareerProps = {
	content?: IgniteYourCareerContent;
	type?: "primary" | "secondary" | "tertiary";
	firstImageOnly?: boolean;
	rightBackground?: string;
	title1Parallax?: string[];
	title2Parallax?: string[];
	title1Left?: string;
	title2Left?: string;
	title1LeftSmaller?: string;
	title2LeftSmaller?: string;
	title1ParallaxSmaller?: string[];
	title2ParallaxSmaller?: string[];
    customFontSize?: string;
};

const Contain = styled.div`
	width: 100%;
	display: block;
`;

const Left = styled.div`
	position: absolute;
	white-space: nowrap;
	right: 51.4%;
	width: 48.6%;
	max-width: 700px;
	height: auto;
	max-height: 850px;
`;

const ImageContainer = styled.div`
	width: 100%;
	display: block;
	position: relative;
	background-position: top left;
	background-repeat: no-repeat;
	overflow: hidden;
`;

const Right = styled.div`
	position: absolute;
	left: 48.6%;
	width: 51.4%;
	max-width: 740px;
	padding-right: 140px;
	padding-top: 100px;
	overflow: hidden;
	box-sizing: border-box;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.xl}) {
				padding-right: 20px;
				padding-top: 20px;
			}
		`;
	}}
`;

const AccordionContainer = styled.div`
	margin-left: auto;
	width: 65%;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.xl}) {
				width: 85%;
			}
		`;
	}}
`;

const ButtonLinkContainer = styled.div`
	display: block;
	width: 100%;
	padding-top: 30px;
	padding-bottom: 80px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.xl}) {
				padding-bottom: 30px;
			}
		`;
	}}
`;

const TitleContainer = styled.div`
	position: absolute;
	right: -18px;
	top: 0;
	height: 100%;
	width: 150px;
	z-index: 2;
`;

const Title1 = styled.div`
	position: absolute;
	transform: rotate(270deg);
	top: 330px;
	white-space: pre;
	font-size: 100px;
	letter-spacing: -0.04em;
	${(props: any) => {
		const { theme } = props;
		return css`
			font-family: ${theme.typography.fontFamilies.headings};
			color: ${theme.colors.primary.white};
			@media only screen and (max-width: ${theme.breakpoints.xl}) {
				top: 195px;
				font-size: 60px;
			}
		`;
	}}
`;
const Title2 = styled.div`
	position: absolute;
	white-space: pre;
	transform: rotate(270deg);
	top: 330px;
	font-size: 100px;
	letter-spacing: -0.04em;
	${(props: any) => {
		const { theme } = props;
		return css`
			font-family: ${theme.typography.fontFamilies.headings};
			color: ${theme.colors.primary.white};
			@media only screen and (max-width: ${theme.breakpoints.xl}) {
				font-size: 60px;
				top: 210px;
			}
		`;
	}}
`;

const MobileCard = styled.div`
	width: 100%;
	display: block;
	padding-bottom: 45px;
	position: relative;
	margin-top: 60px;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.tertiary.background};
		`;
	}}
`;

const MobileCardImage = styled.div`
	width: calc(100% - 20px);
	position: relative;
	top: -40px;
	margin-bottom: -40px;
`;

const MobileCardText = styled.div`
	margin-top: 25px;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	box-sizing: border-box;
`;

const IgniteYourCareer: React.FC<IgniteYourCareerProps> = ({
	content,
	title1Parallax = ["70px", "0px"],
	title2Parallax = ["-70px", "0px"],
	type = "secondary",
	firstImageOnly = false,
	rightBackground,
	title1Left = "-380px",
	title2Left = "-170px",
	title1LeftSmaller = "-190px",
	title2LeftSmaller = "-50px",
	title1ParallaxSmaller = ["70px", "0px"],
	title2ParallaxSmaller = ["-70px", "0px"],
    customFontSize
}) => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();
	if (!content) content = IgniteYourContent[language];

	const [rightHeight, setRightHeight] = React.useState(850);
	const [sectionIndex, setSectionIndex] = React.useState<number>(0);
	const [currentImage, setCurrentImage] = React.useState<any>(
		content.cards[sectionIndex].desktopImage
	);
	React.useEffect(() => {
		if (document && window) {
			const setComponentHeight = () => {
				//let outerWidth = document.body.scrollWidth;
				let windowWidth = document.body.scrollWidth;
				let sectionWidth = windowWidth * 0.486;
				let height = 0;
				let aspect = 700 / 850;
				if (sectionWidth >= 700) height = 850;
				else height = sectionWidth / aspect;
				setRightHeight(height);
			};
			window.addEventListener("resize", function () {
				setComponentHeight();
			});
			setComponentHeight();
		}
	}, []);
	React.useEffect(() => {
		if (content?.cards[sectionIndex].desktopImage !== currentImage) {
			setTimeout(() => {
				setCurrentImage(content?.cards[sectionIndex].desktopImage);
			}, 500);
		}
	}, [sectionIndex]);

	return (
		<ParallaxProvider>
			<Media greaterThanOrEqual="lg">
				<ContentSection type={type} vPadding="0" hPadding="0">
					<Contain
						css={css`
							height: ${rightHeight + "px"};
						`}
					>
						<Left
							css={css`
								height: ${rightHeight + "px"};
							`}
						>
							<ImageContainer
								css={css`
									height: ${rightHeight + "px"};
									background-image: url(${currentImage});
									background-size: cover;
								`}
							>
								{content.cards.map((card: IgniteYourCareerCard, index: number) => {
									return (
										<img
											key={index}
											css={css`
												width: 100%;
												height: auto;
												display: block;
												position: absolute;
												transition: ${firstImageOnly === true
													? "none"
													: "top ease 0.5s"};
												z-index: ${sectionIndex === index ? 1 : -1};
												top: ${sectionIndex === index ? "0px" : "100%"};
											`}
											src={card.desktopImage}
										/>
									);
								})}
							</ImageContainer>
							<TitleContainer>
								<Media greaterThan="lg">
									<Parallax y={title1Parallax}>
										<Title1
											css={css`
												left: ${title1Left};
												@media only screen and (max-width: ${theme
														.breakpoints.xl}) {
													left: ${title1LeftSmaller};
												}
                                                font-size: ${customFontSize ? customFontSize : "100px"};
											`}
											theme={theme}
										>
											{content.title1}
										</Title1>
									</Parallax>
									<Parallax y={title2Parallax}>
										<Title2
											css={css`
												left: ${title2Left};
												@media only screen and (max-width: ${theme
														.breakpoints.xl}) {
													left: ${title2LeftSmaller};
												}
                                                font-size: ${customFontSize ? customFontSize : "100px"};    
											`}
											theme={theme}
										>
											{content.title2}
										</Title2>
									</Parallax>
								</Media>
								<Media lessThan="xl">
									<Parallax y={title1ParallaxSmaller}>
										<Title1
											css={css`
												left: ${title1LeftSmaller};
											`}
											theme={theme}
										>
											{content.title1}
										</Title1>
									</Parallax>
									<Parallax y={title2ParallaxSmaller}>
										<Title2
											css={css`
												left: ${title2LeftSmaller};
											`}
											theme={theme}
										>
											{content.title2}
										</Title2>
									</Parallax>
								</Media>
							</TitleContainer>
						</Left>
						<Right
							theme={theme}
							css={css`
								height: ${rightHeight + "px"};
								background-color: ${rightBackground
									? rightBackground
									: theme.colors[type].background};
							`}
						>
							<AccordionContainer theme={theme}>
								{content.description ? (
									<Typography
										variant="div"
										css={css`
											margin-top: 0px;
											margin-bottom: 50px;
											@media (max-width: ${theme.breakpoints.xl}) {
												margin-top: 20px;
												margin-bottom: 30px;
											}
										`}
										size="md"
										type="tertiary"
									>
										{content.description}
									</Typography>
								) : (
									<></>
								)}
								<Accordion
									variant="line"
									multiple={false}
									defaultOpen={sectionIndex}
									tabClicked={(tabIndex) => {
										setSectionIndex(Number(tabIndex));
									}}
									type="tertiary"
								>
									{content &&
										content.cards.map(
											(card: IgniteYourCareerCard, index: number) => (
												<AccordionTab
													key={`tab-${index}`}
													header={card.title}
													circled={true}
													type="tertiary"
												>
													<Typography
														variant="h2"
														type="tertiary"
														css={css`
															margin-top: 52px;
															margin-bottom: 40px;
															@media (max-width: ${theme.breakpoints
																	.xl}) {
																margin-top: 5px;
																margin-bottom: 4px;
																font-size: 20px;
                                                                ${language === "fr"
                                                                    ? "margin-top:20px; margin-bottom: 20px;"
                                                                    : ""}
															}
                                                            ${language === "fr"
                                                                ? "font-size:20px; line-height:22px;"
                                                                : ""}
														`}

													>
														{card.title}
													</Typography>
													<Typography
														variant="p"
														size="md"
														type="tertiary"
														css={css`
															@media (max-width: ${theme.breakpoints
																	.xl}) {
																margin-top: 0px;
																margin-bottom: 5px;
																font-size: 14px;
															}
														`}
													>
														{card.description}
													</Typography>
													{card.actionLabel && card.actionUrl ? (
														<ButtonLinkContainer theme={theme}>
															<ButtonLink
																size="sm"
																label={card.actionLabel}
																to={card.actionUrl}
																type="tertiary"
															/>
														</ButtonLinkContainer>
													) : (
														<></>
													)}
												</AccordionTab>
											)
										)}
								</Accordion>
							</AccordionContainer>
						</Right>
					</Contain>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection vPadding="20px" hPadding="0px" type="primary">
					{content.cards.map((card: IgniteYourCareerCard, index: number) => {
						return (
							<MobileCard
								key={index}
								theme={theme}
								css={css`
									margin-bottom: ${content && index === content.cards.length - 1
										? "40px"
										: "0px"};
								`}
							>
								<MobileCardImage>
									<img
										css={css`
											width: 100%;
											height: auto;
											display: block;
											margin-left: ${index % 2 === 0 ? "0px" : "20px"};
										`}
										src={card.mobileImage}
									/>
								</MobileCardImage>
								<MobileCardText>
									<Link to={card.actionUrl ?? ""}>
										<Typography
											variant="h3"
											type="tertiary"
											css={css`
												margin-bottom: 10px;
											`}
										>
											{card.title}
										</Typography>
									</Link>
									<Typography type="tertiary" size="md">
										{card.description}
									</Typography>
								</MobileCardText>
							</MobileCard>
						);
					})}
				</ContentSection>
			</Media>
		</ParallaxProvider>
	);
};

export default IgniteYourCareer;
